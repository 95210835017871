import React from 'react'
import App from 'App'
import SEO from 'components/SEO'
import { H1 } from '../components/Typography'
import { Box, Flex } from '../components/Layout'
import HeaderLean from '../components/HeaderLean'
import BlockOfTextWithTableOfContents from '../components/BlockOfTextWithTableOfContents'
import Footer from '../components/Footer'

const content = [
  {
    name: 'guestblogging',
    title: 'Interested In Becoming An Agency Vista Guest Blogger?',
    body: `
     Hi there! Thanks for your interest in writing a guest blog for Agency Vista. We're glad you're here and are always looking for more brilliant contributors to join our ranks. 
     <br>
     <br>
    If you have exceptional writing and/or design skills and would like to share your expertise with a large audience of marketers, growth hackers, and business owners, we'd love to hear from you.
    <br>
    <br>
    Please take some time to review this entire page — it should answer any questions you have about what kind of content we're looking for and how the submission process works.
    <br>
    <br>
    Also, we value your pitch, but due to the volume of requests we receive, we cannot respond to all submissions.
      `,
  },
  {
    name: 'bareessentials',
    title: 'The Bare Essentials for Every Post We Publish',
    body: `
      Successful guest contributions are comprehensive, data-driven, and interesting posts that teach our readers something new that is related to the digital marketing agency landscape. While we tend to skew toward content about specific marketing tactics, that's not all we talk about.
      <br>
      <br>
      We're also interested in publishing any topic that marketers care about, which includes things like hiring, team development, job hunting, writing, design, math, and larger internet trends, among other things.
      <br>
      <br>
      We also look for a few things in everything we publish:
      <br>
        <ul>
            <li>Original concepts, compelling arguments, and high-quality writing. We will not republish anything that's been published elsewhere.</li>
            <li>Article reflects the writing style/tone of the Marketing Blog. We aim to be casual, yet helpful, and typically we stay away from buzzwords and jargon.</li>
            <li>Proper attribution of data, quotations, and outside content referenced in the article. <i>Note: All data should have originated within the last two years</i></li>
            <li>No more than one link to your company's website <i>(Note: This includes your website homepage, blog, pricing page, etc ...)</i> in the body of the post.</li>
            <li>Link to at least 2-5 other Agency Vista pages in your piece. For example, if you're writing a piece on content marketing, link back to an agency profile that specializes in this or a blog post that can compliment the post. </li>
        </ul>
      `,
  },
  {
    name: 'whatwewontaccept',
    title: `What We Won't Accept`,
    body: `
    There are some things we simply can't accept:
    <br>
        <ul>
            <li>Anything that's been covered on our blog before. Please do a search of our site before submitting your articles.</li>
            <li>Anything that may be construed as a link-building scheme.</li>
            <li>Anything that's too promotional for your company or organization.</li>
            <li>Anything that's offensive or inaccurate.</li>
            <li>Anything that's overly critical of individuals or companies — this is not a site to air grievances.</li>
        </ul>
     `,
  },
  {
    name: 'thenotsofineprint',
    title: 'The Not-So-Fine Print ',
    body: `
     Submissions must meet the Agency Vista marketing team's quality standards in order to get published. Editors reserve the right to reject contributions at their discretion.
      <br>
      <br>
      We cannot allow you to republish your guest post to your own blog, LinkedIn, Medium, or Inbound.org afterward. 
      <br>
      <br>
      The Agency Vista marketing team reserves the right to edit and adapt your guest blog content as we see fit, and update it in the future for accuracy and comprehensiveness.
      <br>
      <br>
      Agency Vista reserves the right to include calls-to-action to Agency Vista content, including but not limited to email newsletters, ebooks, and other downloadable content.
      <br>
      <br>
      In rare cases, contributed posts may be removed from the blog and recycle the URL.
      `,
  },
  {
    name: 'howtosubmit',
    title: 'How to Submit An Agency Vista Guest Blogging Post',
    body: `
     <b>Step 1:</b> Become a verified agency on our platform to gain access to guest blogging opportunities. If you don’t have a profile simply sign up for free and complete the tasks in your profile dashboard. If you have already claimed your profile, complete the necessary steps to become a verified agency. 
     <br>
     <br>
     <b>Step 2:</b> Format your post appropriately.
     <br>
     <ul>
        <li>Don't sacrifice depth for the sake of brevity. We don't enforce a strict word count on the blog, but most articles should fall in the 1,000 - 1,800 word range. Instead of trying to hit a specific word count, focus on clear, in-depth explanations that readers of different levels can understand and learn from. It's better to over-explain a concept than under-explain and leave some readers in the dark.</li> 
        <li>The best blog copy is simple, accessible, and clear. Don't get stuck trying to make a complex sentence structure work when a simple one works better. Take the most direct route to your points, use your natural voice, and avoid unnecessary filler words. </li> 
        <li>Paragraphs should be no more than three to five sentences long and formatted using H2s, H3s, and H4s, when appropriate.</li> 
        <li>Add bulleted lists to help break up dense copy chunks. Numbered lists should be formatted as number + period.</li>
        <li>Always include a conclusion.</li>
        <li>When including images, gifs, or screenshots, cite the image source as: “Image source” and hyperlink that text with the page you found the image on.</li>
        <li>Tip: Copy and paste your post into Grammarly, or Microsoft Word and run a spell check. We prefer these editing tools for catching sneaky misspellings, and extra spaces. Feeling extra word-nerdy? Use <a href="http://www.hemingwayapp.com?utm_source=agencyvista.com" target="_blank" rel="noreferrer noopener">Hemingway Editor</a> to check for run-on sentences, difficult sentence structure, etc ..</li>
    </ul>
    <br>
    <b>Step 3:</b> Submit your pitch of finished blog post to <a href="mailto:yourfriends@agencyvista.com">yourfriends@agencyvista.com</a> with the following:
    <br>
    <ul>
        <li>Formatted subject line: <i>“Guest Pitch: [Tentative Title of Post]”</i></li>
        <li>Your completed post or pitch in a Google Document with editing permissions turned on for “anyone with the link.”</li>
        <li>If you're including images, make sure they're compressed and add them into the Google Document, and provide proper attribution below each image (e.g., <a href="https://www.grammy.com/grammys/artists/celine-dion?utm_source=agencyvista.com" target="_blank" rel="noreferrer noopener">Image source</a>).</li>
        <li>Short author bio, headshot, and any links to your LinkedIn or Twitter accounts you'd like linked.</li>
    </ul>
    <br>
    If your article meets editorial standards and aligns with our content strategy, we will respond to let you know your article will be published. That process may take up to 2 weeks and the publish date could exceed this timeline based on the needs of our editorial calendar.
    <br>
    <br>
    Due to the volume of requests we receive, we cannot respond to all submissions.
      `,
  },
]

const GuestBlogging = () => (
  <App isHeaderVisible={false}>
    <SEO title="Guest Blogging" description="Learn more about guest blogging for Agency Vista" path="/blogging/" />
    <HeaderLean />
    <Flex mt="xl" flexDirection="column" alignItems="center">
      <H1>Guest Blogging</H1>
    </Flex>
    <Box m="0 auto" px="m" mt="xl" maxWidth="1080px">
      <BlockOfTextWithTableOfContents content={content} />
    </Box>
    <Box my="xl">
      <Footer />
    </Box>
  </App>
)

export default GuestBlogging
